import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from "./components/AuthContext"; // Import the AuthProvider
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./utils/i18n/index";
import App from "./App";
import Signin from "./components/Signin";
import Item from "./components/ItemInterface";
import Register from "./components/Register";
import Payment from "./components/Payment";
import SideNav from "./components/SideNav";
import SearchPage from "./components/SearchPage";
import YourItem from "./components/YourItem";
import UserProfilePage from "./components/Profile";
import OrganizationPage from "./components/Organizations";
import ComponentSwitcher from "./components/DashboardAdmin";
import AuthSuccess from "./components/AuthSuccess";
import CardAuthentication from "./components/PaymentAuth";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />, // App is the root component
  },
  {
    path: "signin",
    element: <Signin />, // Define child route here
  },
  {
    path: "/item/:auctionId?",
    element: <Item />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/profile",
    element: <SideNav />,
    children: [
      {
        path: "/profile/payment",
        element: <Payment />,
      },
      {
        path: "/profile/items",
        element: <YourItem />,
      },
    ],
  },
  {
    path: "/auth-success",
    element: <AuthSuccess />,
  },
  {
    path: "/search/:searchType",
    element: <SearchPage />,
  },
  {
    path: "/user/:user_id",
    element: <UserProfilePage />,
  },
  {
    path: "/organization/:orgId",
    element: <OrganizationPage />,
  },
  {
    path: "/admin",
    element: <ComponentSwitcher />,
  },
  {
    path: "/payment",
    element: <CardAuthentication />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      {" "}
      {/* Wrap with AuthProvider */}
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

