import React, { useState } from "react";
import axios from "axios";

const AdminDashboard = () => {
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    description: "",
    img_url: "",
    category: "",
    seller: "",
    start_date: "",
    start_hour: "",
    start_minute: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Combine date, hour, and minute into a single ISO string for start_date
    const fullStartDate = new Date(
      `${formData.start_date}T${formData.start_hour.padStart(
        2,
        "0"
      )}:${formData.start_minute.padStart(2, "0")}:00`
    );

    // If the server expects a specific timezone, this might help adjust it
    const correctedDate = new Date(
      fullStartDate.getTime() - fullStartDate.getTimezoneOffset() * 60000
    );

    try {
      const response = await axios.post(
        "https://auction-0u10.onrender.com/api/item",
        {
          ...formData,
          start_date: correctedDate.toISOString(), // Send the ISO string with corrected time
        },
        {
          withCredentials: true, // Ensures cookies are sent
        }
      );
      console.log("Item created successfully:", response.data);
      alert("Item created successfully!");
    } catch (error) {
      console.error("Error creating item:", error);
      alert("Failed to create item.");
    }
  };

  return (
    <div className="container mt-5">
      <h2>Create New Item</h2>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Item Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="price" className="form-label">
            Price
          </label>
          <input
            type="number"
            className="form-control"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows={3}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="img_url" className="form-label">
            Image URL
          </label>
          <input
            type="text"
            className="form-control"
            id="img_url"
            name="img_url"
            value={formData.img_url}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Category
          </label>
          <input
            type="text"
            className="form-control"
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="seller" className="form-label">
            Seller (User ID)
          </label>
          <input
            type="text"
            className="form-control"
            id="seller"
            name="seller"
            value={formData.seller}
            onChange={handleChange}
            required
          />
        </div>

        {/* Start Date */}
        <div className="mb-3">
          <label htmlFor="start_date" className="form-label">
            Start Date (YYYY-MM-DD)
          </label>
          <input
            type="date"
            className="form-control"
            id="start_date"
            name="start_date"
            value={formData.start_date}
            onChange={handleChange}
            required
          />
        </div>

        {/* Start Hour */}
        <div className="mb-3">
          <label htmlFor="start_hour" className="form-label">
            Start Hour (HH)
          </label>
          <input
            type="number"
            className="form-control"
            id="start_hour"
            name="start_hour"
            value={formData.start_hour}
            onChange={handleChange}
            min="0"
            max="23"
            required
          />
        </div>

        {/* Start Minute */}
        <div className="mb-3">
          <label htmlFor="start_minute" className="form-label">
            Start Minute (MM)
          </label>
          <input
            type="number"
            className="form-control"
            id="start_minute"
            name="start_minute"
            value={formData.start_minute}
            onChange={handleChange}
            min="0"
            max="59"
            required
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Create Item
        </button>
      </form>
    </div>
  );
};

export default AdminDashboard;
