// AuthContext.js
import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const savedAuthState = localStorage.getItem("isAuthenticated");
    const savedUserId = localStorage.getItem("userId");
    if (savedAuthState) {
      setIsAuthenticated(JSON.parse(savedAuthState));
      setUserId(savedUserId);
    }
  }, []);

  const handleSignIn = (id) => {
    setIsAuthenticated(true);
    setUserId(id);
    localStorage.setItem("isAuthenticated", JSON.stringify(true));
    localStorage.setItem("userId", id);
  };

  const handleSignOut = async () => {
    const response = await fetch(
      "https://auction-0u10.onrender.com/api/auth/logout",
      {
        method: "POST",
        credentials: "include",
      }
    );
    if (response.ok) {
      setIsAuthenticated(false);
      setUserId(null);
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("userId");
    }
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userId, handleSignIn, handleSignOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};
