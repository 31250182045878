import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { AuthContext } from "./AuthContext";

const langs = ["ge", "en", "ru"];

export default function Navbar({ searchType }) {
  const { isAuthenticated, userId, handleSignOut } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate(); // Initialize useNavigate
  const [searchQuery, setSearchQuery] = useState(""); // State for search input

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
  };

  // Adjusted handleSearchSubmit to handle optional parameter
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      // Use searchType parameter if provided
      const searchPath = searchType
        ? `/search/${searchType}?q=${encodeURIComponent(searchQuery)}`
        : `/search?q=${encodeURIComponent(searchQuery)}`;
      navigate(searchPath);
    }
  };

  return (
    <>
      <nav className="navbar sticky-top navbar-expand-sm navbar-dark bg-dark">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <h2>AuctionHouse</h2>
          </Link>
          <form className="d-flex me-auto" onSubmit={handleSearchSubmit}>
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="btn btn-light btn-outline-success" type="submit">
              {t("search")}
            </button>
          </form>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <div className="d-flex align-items-center">
              {isAuthenticated ? (
                <button
                  className="btn btn-outline-light me-2"
                  type="button"
                  onClick={handleSignOut}
                >
                  {t("logout")}
                </button>
              ) : (
                <>
                  <Link to="/signin">
                    <button
                      className="btn btn-outline-light me-2"
                      type="button"
                    >
                      {t("navSignin")}
                    </button>
                  </Link>
                  <Link to="/register">
                    <button
                      className="btn btn-outline-light me-2"
                      type="button"
                    >
                      {t("navRegister")}
                    </button>
                  </Link>
                </>
              )}
              <button
                className="btn btn-outline-light me-2"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {t("sidebar")}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body d-flex flex-column justify-content-between">
          <ul className="list-unstyled ps-0">
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse"
                aria-expanded="true"
              >
                {t("home")}
              </button>
              <div className="collapse show" id="home-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <a href={`/user/${userId}`} className="link-dark rounded">
                      {t("profile")}
                    </a>
                  </li>
                  <li>
                    <a href="/profile/payment" className="link-dark rounded">
                      {t("billing")}
                    </a>
                  </li>
                  <li>
                    <a href="/profile/upload" className="link-dark rounded">
                      {t("upload")}
                    </a>
                  </li>
                  <li>
                    <a href="/profile/items" className="link-dark rounded">
                      {t("items")}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            {/* Additional Navbar items go here... */}
          </ul>
          <div className="mt-auto">
            <button
              className="btn btn-outline-dark me-2"
              onClick={() => changeLanguage(langs[0])}
            >
              🇬🇪
            </button>
            <button
              className="btn btn-outline-dark me-2"
              onClick={() => changeLanguage(langs[1])}
            >
              🇺🇸
            </button>
            <button
              className="btn btn-outline-dark"
              onClick={() => changeLanguage(langs[2])}
            >
              🇷🇺
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
