import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

export default function AuthSuccess() {
  const { handleSignIn } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Call handleSignIn to set the authentication state
    handleSignIn();
    // Redirect to the main page after successful login
    navigate("/");
  }, [handleSignIn, navigate]);

  return null;
}
