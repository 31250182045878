import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Main() {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);

  // Fetch items from API
  useEffect(() => {
    async function fetchItems() {
      try {
        const response = await fetch(
          "https://auction-0u10.onrender.com/api/newItems",
          { credentials: "include" }
        );
        const data = await response.json();
        setItems(data);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    }

    fetchItems();
  }, []);
  return (
    <>
      <div
        className="carousel slide"
        id="carouselExampleCaptions"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="https://s3-eu-west-1.amazonaws.com/cdn.atgmedia.com/the-saleroom/website/marketing/homepage/image/sr-hero-20201126-ceramics.jpg"
              className="d-block w-100"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>First slide label</h5>
              <p>
                Some representative placeholder content for the first slide.
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="https://s3-eu-west-1.amazonaws.com/cdn.atgmedia.com/the-saleroom/website/marketing/homepage/image/sr-hero-20201130-fine-art.jpg"
              className="d-block w-100"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Second slide label</h5>
              <p>
                Some representative placeholder content for the second slide.
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="https://s3-eu-west-1.amazonaws.com/cdn.atgmedia.com/the-saleroom/website/marketing/homepage/image/sr-hero-20201211-collectables.jpg"
              className="d-block w-100"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Third slide label</h5>
              <p>
                Some representative placeholder content for the third slide.
              </p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className="container mt-4">
        <h2>{t("mainCurrentAuctions")}</h2>
        <div className="row mt-4">
          {items.map((item) => (
            <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={item.item_id}>
              <div className="card">
                <img
                  src={item.img_url}
                  className="card-img-top"
                  alt={item.name}
                />
                <div className="card-body">
                  <h5 className="card-title">{item.name}</h5>
                  <p className="card-text">{item.description}</p>
                  <p className="card-text">${item.price}</p>
                  <Link
                    to={`/item/${item.item_id}`}
                    className="btn btn-primary"
                  >
                    {t("viewDetails")}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
