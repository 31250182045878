import React, { useState } from "react";
import OrganizationDashboard from "./OrganizationDashboard";
import AdminDashboard from "./Dashboard";
import EmailVerificationForm from "./VerificationDashboard"; // Import the email verification component

const ComponentSwitcher = () => {
  const [selectedComponent, setSelectedComponent] = useState("organization");

  const handleComponentChange = (e) => {
    setSelectedComponent(e.target.value);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Choose a Dashboard</h2>

      {/* Bootstrap radio buttons for component selection */}
      <div className="d-flex justify-content-center mb-4">
        <div className="form-check me-3">
          <input
            className="form-check-input"
            type="radio"
            name="component"
            id="organization"
            value="organization"
            checked={selectedComponent === "organization"}
            onChange={handleComponentChange}
          />
          <label className="form-check-label" htmlFor="organization">
            Organization Dashboard
          </label>
        </div>

        <div className="form-check me-3">
          <input
            className="form-check-input"
            type="radio"
            name="component"
            id="another"
            value="another"
            checked={selectedComponent === "another"}
            onChange={handleComponentChange}
          />
          <label className="form-check-label" htmlFor="another">
            Admin Dashboard
          </label>
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="component"
            id="emailVerification"
            value="emailVerification"
            checked={selectedComponent === "emailVerification"}
            onChange={handleComponentChange}
          />
          <label className="form-check-label" htmlFor="emailVerification">
            Email Verification
          </label>
        </div>
      </div>

      {/* Conditionally render the selected component */}
      <div className="card p-3">
        {selectedComponent === "organization" && <OrganizationDashboard />}
        {selectedComponent === "another" && <AdminDashboard />}
        {selectedComponent === "emailVerification" && <EmailVerificationForm />}
      </div>
    </div>
  );
};

export default ComponentSwitcher;
