import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function RelatedItemsSlider({ categoryId }) {
  const [relatedItems, setRelatedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchRelatedItems = async () => {
      try {
        const response = await fetch(
          `https://auction-0u10.onrender.com/api/category/${categoryId}/item`,
          {
            credentials: "include",
          }
        );
        const data = await response.json();

        if (response.ok) {
          // Access the "items" array from the response and set it
          setRelatedItems(data.items);
          setIsLoading(false);
        } else {
          setError(data.error);
          setIsLoading(false);
        }
      } catch (error) {
        setError("Error fetching related items.");
        setIsLoading(false);
      }
    };

    fetchRelatedItems();
  }, [categoryId]);

  if (isLoading) {
    return <p>Loading related items...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Slider {...settings}>
      {relatedItems.map((item) => (
        <div key={item.item_id} className="card p-3" style={{ width: "18rem" }}>
          <Link to={`/item/${item.item_id}`}>
            <img
              src={item.img_url} // Updated from item.image_url to item.img_url
              alt={item.name}
              className="card-img-top"
            />
            <div className="card-body">
              <h5 className="mt-2 card-text">{item.name}</h5>
              <p className="card-text">{item.description}</p>
              <p>Likes: {item.likes}</p>
            </div>
          </Link>
        </div>
      ))}
    </Slider>
  );
}
