import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLang from "./en/en.json";
import geLang from "./ge/ge.json";

const resources = {
  en: {
    translation: enLang,
  },
  ge: {
    translation: geLang,
  },
};

// Retrieve the language from localStorage or default to "en"
const savedLanguage = localStorage.getItem("i18nextLng") || "en";

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  lng: savedLanguage, // Use the saved language from localStorage
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
