// App.js
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Main from "./components/Main";
import AuthSuccess from "./components/AuthSuccess";
import Signin from "./components/Signin";
import React, { useState } from "react";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSignIn = () => setIsAuthenticated(true);
  console.log(isAuthenticated);
  return (
    <div>
      <Navbar isAuthenticated={isAuthenticated} searchType="items" />
      <Routes>
        <Route path="*" element={<Main />} />
        <Route path="/auth-success" element={<AuthSuccess />} />
        <Route
          path="/signin"
          element={<Signin handleSignIn={handleSignIn} />} // Pass handleSignIn here
        />
      </Routes>
    </div>
  );
}

export default App;

