import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const EmailVerificationForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(null); // Track success status

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a POST request to the email verification API route
      const response = await axios.post(
        "/api/verify-by-email",
        { withCredentials: true },
        { email }
      );

      // Update message and success state based on the API response
      setMessage(response.data.message);
      setIsSuccess(true);
    } catch (error) {
      setMessage(
        error.response?.data?.message || "An error occurred. Please try again."
      );
      setIsSuccess(false);
    }
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit} className="w-50 mx-auto">
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>

      {/* Display the message based on the response */}
      {message && (
        <div
          className={`alert mt-3 ${
            isSuccess ? "alert-success" : "alert-danger"
          }`}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default EmailVerificationForm;
