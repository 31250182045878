import React, { useState } from "react";
import { Link } from "react-router-dom";
import PriceCategoryAndDateFilterComponent from "./Slider";
import "../css/Item.css";
export default function YourItem() {
  const [cards] = useState([
    {
      id: 1,
      title: "Item 1",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      image:
        "https://portal-images.azureedge.net/auctions-2024/srsta10330/images/8da57053-4217-4abc-a2a7-b19c00ababe3.jpg?h=1000",
      price: 100,
      startDate: "2024-08-01",
      category: "Electronics",
    },
    {
      id: 2,
      title: "Item 2",
      description:
        "Another example text to build on the card title and make up the bulk of the card's content.",
      image:
        "https://portal-images.azureedge.net/auctions-2024/srsta10330/images/8da57053-4217-4abc-a2a7-b19c00ababe3.jpg?h=1000",
      price: 150,
      startDate: "2024-08-03",
      category: "Clothing",
    },
    {
      id: 3,
      title: "Item 3",
      description:
        "Another example text to build on the card title and make up the bulk of the card's content.",
      image:
        "https://portal-images.azureedge.net/auctions-2024/srsta10330/images/8da57053-4217-4abc-a2a7-b19c00ababe3.jpg?h=1000",
      price: 350,
      startDate: "2024-09-03",
      category: "Home",
    },
    {
      id: 4,
      title: "Item 4",
      description:
        "Another example text to build on the card title and make up the bulk of the card's content.",
      image:
        "https://portal-images.azureedge.net/auctions-2024/srsta10330/images/8da57053-4217-4abc-a2a7-b19c00ababe3.jpg?h=1000",
      price: 50,
      startDate: "2024-09-10",
      category: "Electronics",
    },
    {
      id: 5,
      title: "Item 5",
      description:
        "Another example text to build on the card title and make up the bulk of the card's content.",
      image:
        "https://portal-images.azureedge.net/auctions-2024/srsta10330/images/8da57053-4217-4abc-a2a7-b19c00ababe3.jpg?h=1000",
      price: 500,
      startDate: "2024-01-10",
      category: "Clothing",
    },
  ]);

  const [filteredCards, setFilteredCards] = useState(cards);

  // Function to handle filtering from the filter component
  const handleFilter = (priceRange, selectedCategories, dateRange) => {
    const filtered = cards.filter((card) => {
      const categoryStatus = selectedCategories[card.category];
      const cardDate = new Date(card.startDate);
      const startDate = dateRange.start ? new Date(dateRange.start) : null;
      const endDate = dateRange.end ? new Date(dateRange.end) : null;

      return (
        card.price >= priceRange[0] &&
        card.price <= priceRange[1] &&
        (categoryStatus === null || categoryStatus === "selected") &&
        !(categoryStatus === "excluded") &&
        (!startDate || cardDate >= startDate) &&
        (!endDate || cardDate <= endDate)
      );
    });
    setFilteredCards(filtered);
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          {/* Filter on the left side */}
          <div className="col-md-3">
            <PriceCategoryAndDateFilterComponent onFilter={handleFilter} />
          </div>

          {/* Cards on the right side */}
          <div className="col-md-9">
            <div className="row">
              {filteredCards.length > 0 ? (
                filteredCards.map((card) => (
                  <div className="col-md-4 mb-4" key={card.id}>
                    <div className="card">
                      <img
                        src={card.image}
                        className="card-img-top"
                        alt={card.title}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{card.title}</h5>
                        <p className="card-text">{card.description}</p>
                        <Link
                          to={`/item/${card.id}`}
                          className="btn btn-primary"
                        >
                          View Item
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No items found that match your filters.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
