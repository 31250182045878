import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Navbar from "./Navbar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/Item.css";
import { useTranslation } from "react-i18next";
import { ImageZoom } from "react-responsive-image-zoom";
import Auction from "./Item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as filledHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as outlinedHeart } from "@fortawesome/free-regular-svg-icons";
import RelatedItemsSlider from "./ItemSlider";

const Countdown = ({ startDate }) => {
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();

      // Convert the startDate to a UTC+4 time (assuming it's saved as +4 UTC)
      const auctionStart = new Date(startDate).getTime() - 4 * 60 * 60 * 1000; // Subtract 4 hours from the saved time
      const distance = auctionStart - now;

      if (distance < 0) {
        clearInterval(intervalId);
        setTimeRemaining("Auction has started!");
      } else {
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startDate]);

  return <p>{timeRemaining}</p>;
};

export default function Item() {
  const { t } = useTranslation();
  const { auctionId } = useParams();

  // State for item data
  const [currentItem, setCurrentItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for likes
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [seller, setSeller] = useState(null); // State for seller data
  // Fetch item details from API

  // Fetch item details and like status from API
  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await fetch(
          `https://auction-0u10.onrender.com/api/item/${auctionId}`,
          {
            credentials: "include",
          }
        );
        const data = await response.json();

        if (response.ok) {
          setCurrentItem(data);
          setLikes(data.likes);
          setIsLoading(false);
        } else {
          setError(data.error);
          setIsLoading(false);
        }
      } catch (error) {
        setError("Error fetching item details.");
        setIsLoading(false);
      }
    };

    const fetchLikeStatus = async () => {
      try {
        const response = await fetch(
          `https://auction-0u10.onrender.com/api/item/${auctionId}/like-status`,
          {
            credentials: "include",
          }
        );
        const data = await response.json();

        if (response.ok) {
          setIsLiked(data.isLiked);
          setLikes(data.likes);
        } else {
          console.error("Error fetching like status:", data.error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    const fetchSeller = async () => {
      try {
        const response = await fetch(
          `https://auction-0u10.onrender.com/api/item/${auctionId}/author`,
          {
            credentials: "include",
          }
        );
        const data = await response.json();

        if (response.ok) {
          console.log(data.seller);
          setSeller(data.seller);
        } else {
          console.error("Error fetching seller data:", data.error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchSeller();
    fetchItem();
    fetchLikeStatus();
  }, [auctionId]);
  const handleRegister = async () => {
    try {
      fetch(
        `https://auction-0u10.onrender.com/api/auction/${auctionId}/register`,
        {
          method: "POST",
          credentials: "include",
        }
      )
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .catch((error) => {
          console.error("Error:", error);
          // handle errors here
        });
    } catch (error) {
      console.error("Error registering for auction:", error);
    }
  };

  const handleLikeToggle = async () => {
    try {
      const endpoint = isLiked
        ? `https://auction-0u10.onrender.com/api/item/${auctionId}/unlike`
        : `https://auction-0u10.onrender.com/api/item/${auctionId}/like`;

      const response = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
      });

      if (response.ok) {
        // Toggle the heart icon immediately
        setIsLiked(!isLiked);

        // Fetch the latest like status and count
        const statusResponse = await fetch(
          `https://auction-0u10.onrender.com/api/item/${auctionId}/like-status`,
          { credentials: "include" }
        );
        const statusData = await statusResponse.json();

        if (statusResponse.ok) {
          setLikes(statusData.likes); // Update to the latest likes count
        } else {
          console.error("Error fetching like status:", statusData.error);
        }
      } else {
        const data = await response.json();
        console.error("Error:", data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            {currentItem && (
              <ImageZoom
                src={currentItem.img_url}
                defaultZoomFactor={1.8}
                transition={0.5}
                breakpoints={[
                  { maxWidth: 768, zoomFactor: 1.2 },
                  { maxWidth: 1024, zoomFactor: 1.4 },
                ]}
                imgClassName="my-image-class"
              />
            )}
          </div>
          <div className="col-md-6">
            {currentItem && (
              <>
                <h2 className="mt-3">{currentItem.title}</h2>
                <p className="lead">
                  {t("itemPrice")}: ${currentItem.price}
                </p>
                {seller && (
                  <Link
                    to={`/user/${seller.user_id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className="seller-info"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span style={{ marginRight: "10px" }}>{seller.name}</span>
                      <img
                        src={seller.image}
                        alt="Seller"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  </Link>
                )}

                {currentItem.status === "Upcoming" ? (
                  <>
                    <Countdown startDate={currentItem.start_date} />
                    <button
                      className="btn btn-primary"
                      onClick={handleRegister}
                    >
                      {t("registerForAuction")}
                    </button>
                  </>
                ) : currentItem.status === "Active" ? (
                  <>
                    <p>
                      {t("itemStartingBid")}: ${currentItem.price / 10}
                    </p>
                    <Auction auctionId={auctionId} />
                  </>
                ) : (
                  <p>{t("auctionEnded")}</p>
                )}
                <div className="like-section mt-3">
                  <FontAwesomeIcon
                    icon={isLiked ? filledHeart : outlinedHeart}
                    onClick={handleLikeToggle}
                    style={{
                      color: isLiked ? "red" : "black",
                      cursor: "pointer",
                      fontSize: "24px",
                    }}
                  />
                  <span className="ml-2">{likes}</span>
                </div>
                <h4 className="mt-3">{t("itemDescription")}</h4>
                <p>{currentItem.description}</p>
              </>
            )}
          </div>
        </div>
        <h3 className="mt-5">{t("itemOthers")}</h3>
        {currentItem && (
          <RelatedItemsSlider categoryId={currentItem.category_id} />
        )}
      </div>
    </>
  );
}
