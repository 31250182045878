import { NavLink, Outlet } from "react-router-dom";
import "../index.css"; // Ensure you import your CSS file here

export default function SideNav() {
  return (
    <>
      {/* <Navbar /> */}
      <div className="d-flex">
        <nav className="nav-side p-3">
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLink className="nav-link" to="/user">
                Profile
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link active" aria-current="page" to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/profile/payment">
                Payment
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to="/signin">
                Logout
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="content-area p-3">
          <Outlet />
        </div>
      </div>
    </>
  );
}
