import Navbar from "./Navbar";
import { useState } from "react";

export default function Register() {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rpassword: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    handleApi();
  }

  function handleApi() {
    if (user.password !== user.rpassword) {
      // Show validation error using Bootstrap 5 styles
      alert("Passwords do not match.");
      return;
    }

    fetch("https://auction-0u10.onrender.com/api/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: user.password,
      }),
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  }

  function handleGoogle() {
    window.location.href = "https://auction-0u10.onrender.com/auth/google";
  }

  function handleFacebook() {
    window.location.href = "https://auction-0u10.onrender.com/auth/facebook";
  }

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">Register</h3>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      id="firstName"
                      placeholder="Enter your first name"
                      name="firstName"
                      value={user.firstName}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      id="lastName"
                      placeholder="Enter your last name"
                      name="lastName"
                      value={user.lastName}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      id="email"
                      placeholder="Enter your email"
                      name="email"
                      value={user.email}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={handleChange}
                      id="password"
                      placeholder="Enter your password"
                      name="password"
                      value={user.password}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="rpassword" className="form-label">
                      Repeat Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={handleChange}
                      id="rpassword"
                      placeholder="Repeat your password"
                      name="rpassword"
                      value={user.rpassword}
                    />
                  </div>

                  <button type="submit" className="btn btn-primary w-100">
                    Register
                  </button>
                </form>

                <div className="text-center mt-3">
                  <p>Or register with:</p>
                  <button
                    className="btn btn-danger w-100 mb-2"
                    onClick={handleGoogle}
                  >
                    Sign in with Google
                  </button>
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleFacebook}
                  >
                    Sign in with Facebook
                  </button>
                </div>

                <p className="text-center mt-3">
                  Already have an account? <a href="/">Sign in here</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
