import React, { useState, useEffect } from "react";
import ReactSlider from "react-slider";
import { useTranslation } from "react-i18next";
const uniqueCategories = ["Electronics", "Clothing", "Home"];

const PriceCategoryAndDateFilterComponent = ({ onFilter }) => {
  const { t } = useTranslation();
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [categoryState, setCategoryState] = useState(
    uniqueCategories.reduce(
      (acc, category) => ({ ...acc, [category]: null }),
      {}
    )
  );
  const [dateRange, setDateRange] = useState({ start: "", end: "" });

  useEffect(() => {
    onFilter(priceRange, categoryState, dateRange);
  }, [priceRange, categoryState, dateRange, onFilter]); // onFilter is now stable due to useCallback in the parent

  const handleCategoryChange = (category) => {
    setCategoryState((prevState) => {
      const currentState = prevState[category];
      let newState;

      if (currentState === null) {
        newState = "selected"; // Select category
      } else if (currentState === "selected") {
        newState = "excluded"; // Exclude category
      } else {
        newState = null; // Deselect (neutral)
      }

      return { ...prevState, [category]: newState };
    });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prevRange) => ({
      ...prevRange,
      [name]: value,
    }));
  };

  return (
    <div className="card p-3">
      <h5>{t("filterTitle")}</h5>

      {/* Price Slider */}
      <div className="mb-4">
        <h6>Price Range</h6>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="thumb"
          trackClassName="track"
          defaultValue={[0, 10000]}
          min={0}
          max={10000}
          step={10}
          value={priceRange}
          onChange={(value) => setPriceRange(value)}
          ariaLabel={["Lower thumb", "Upper thumb"]}
          ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        />
        <div>
          <span>
            {t("filterMin")}: ${priceRange[0]}
          </span>{" "}
          -{" "}
          <span>
            {t("filterMax")}: ${priceRange[1]}
          </span>
        </div>
      </div>

      {/* Category Filter */}
      <div className="mb-4">
        <h6>{t("filterCategory")}</h6>
        {uniqueCategories.map((category) => {
          const categoryStatus = categoryState[category];
          let label;

          if (categoryStatus === "selected") {
            label = `✔️ ${category} (Selected)`;
          } else if (categoryStatus === "excluded") {
            label = `❌ ${category} (Excluded)`;
          } else {
            label = category; // Neutral state
          }

          return (
            <button
              key={category}
              className="btn btn-outline-secondary btn-sm mb-2"
              onClick={() => handleCategoryChange(category)}
            >
              {label}
            </button>
          );
        })}
      </div>

      {/* Date Filter */}
      <div>
        <h6>Date Range</h6>
        <div className="mb-2">
          <label className="form-label">{t("filterStartDate")}:</label>
          <input
            type="date"
            className="form-control"
            name="start"
            value={dateRange.start}
            onChange={handleDateChange}
          />
        </div>
        <div>
          <label className="form-label">{t("filterEndDate")}:</label>
          <input
            type="date"
            className="form-control"
            name="end"
            value={dateRange.end}
            onChange={handleDateChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceCategoryAndDateFilterComponent;
