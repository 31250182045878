import React, { useEffect, useRef, useState } from "react";
import dropin from "braintree-web-drop-in";
import axios from "axios";

const CardAuthentication = () => {
  const dropinInstance = useRef(null);
  const [clientToken, setClientToken] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch the client token from your backend
  useEffect(() => {
    const fetchClientToken = async () => {
      try {
        const response = await axios.get(
          "https://auction-0u10.onrender.com/api/braintree/client-token"
        );
        setClientToken(response.data.clientToken);
      } catch (error) {
        console.error("Error fetching client token:", error);
      }
    };

    fetchClientToken();
  }, []);

  // Initialize Drop-In UI when clientToken is available
  useEffect(() => {
    if (clientToken) {
      dropin.create(
        {
          authorization: clientToken,
          container: "#dropin-container",
        },
        (error, instance) => {
          if (error) {
            console.error("Error initializing Drop-In UI:", error);
            return;
          }
          dropinInstance.current = instance;
        }
      );
    }
  }, [clientToken]);

  const handleSubmit = async () => {
    if (!dropinInstance.current) {
      console.error("Drop-In instance is not initialized.");
      return;
    }

    setLoading(true);

    try {
      const { nonce } = await dropinInstance.current.requestPaymentMethod();

      // Send the nonce to your backend for validation
      const response = await axios.post(
        "https://auction-0u10.onrender.com/api/braintree/validate-card",
        { withCredentials: true },
        {
          paymentMethodNonce: nonce,
        }
      );

      alert(response.data.message || "Card authenticated successfully!");
    } catch (error) {
      console.error("Error processing payment method:", error);
      alert(error.response?.data?.message || "Error validating card");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Card Authentication</h2>
      {clientToken ? (
        <div>
          <div id="dropin-container" />
          <button onClick={handleSubmit} disabled={loading}>
            {loading ? "Processing..." : "Authenticate Card"}
          </button>
        </div>
      ) : (
        <p>Loading payment gateway...</p>
      )}
    </div>
  );
};

export default CardAuthentication;
