import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const Auction = ({ auctionId }) => {
  const { t } = useTranslation();
  const [highestBid, setHighestBid] = useState(0);
  const [bidAmount, setBidAmount] = useState("");
  const [originalPrice, setOriginalPrice] = useState(0);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(5);
  const [winner, setWinner] = useState(null);
  const ws = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    ws.current = new WebSocket(`ws://localhost:8080/auction/${auctionId}`);

    ws.current.onopen = () => {
      ws.current.send(JSON.stringify({ type: "getHighestBid" }));
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.newBid) {
        setHighestBid(data.newBid);
        setCountdown(5); // Reset countdown when a new bid is received
        setError(null);
      }

      if (data.originalPrice) {
        setOriginalPrice(data.originalPrice);
      }

      if (data.highestBid) {
        setHighestBid(data.highestBid);
      }

      if (data.error) {
        setError(data.error);
      }

      if (data.type === "auctionEnded") {
        setWinner(data.winnerId);
      }
    };

    ws.current.onclose = (event) => {
      console.log("WebSocket connection closed", event);
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket encountered an error:", error);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [auctionId]);

  useEffect(() => {
    if (highestBid > 0 && !winner) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      timerRef.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timerRef.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [highestBid, winner]);

  const handleBidSubmit = (e) => {
    e.preventDefault();
    const newBid =
      bidAmount === "" || 0
        ? Math.floor((Number(highestBid) + originalPrice * 0.1) * 100) / 100
        : Number(bidAmount);
    if (newBid <= highestBid) {
      setError("Your bid must be higher than the current highest bid.");
      return;
    }
    ws.current.send(JSON.stringify({ type: "placeBid", bidAmount: newBid }));
    setBidAmount("");
  };

  return (
    <div>
      <h2>Auction: {auctionId}</h2>
      <p>Current Highest Bid: ${highestBid}</p>
      {winner ? (
        <p>
          Winner: {winner} with ${highestBid}
        </p>
      ) : (
        <div>
          <p>Time left to bid: {countdown} seconds</p>
          <form onSubmit={handleBidSubmit}>
            <label>
              Enter your bid:
              <input
                type="number"
                value={bidAmount}
                placeholder={highestBid + originalPrice * 0.1}
                onChange={(e) => setBidAmount(e.target.value)}
              />
            </label>
            <button type="submit">{t("itemBidButton")}</button>
          </form>
        </div>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default Auction;
