import { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { Badge, Container, Row, Col, Card, Image } from "react-bootstrap";
import PriceCategoryAndDateFilterComponent from "./Slider";
import Navbar from "./Navbar";
import SideNav from "./SideNav";
import axios from "axios";

const UserProfilePage = () => {
  const { user_id } = useParams(); // Get user ID from the URL
  const [user, setUser] = useState(null); // Store user data
  const [items, setItems] = useState([]); // Store items data
  const [filteredItems, setFilteredItems] = useState([]); // Filtered items

  // Fetch user data and user items on component mount
  useEffect(() => {
    // Fetch user data
    axios
      .get(`/api/users/${user_id}`, { withCredentials: true })
      .then((response) => setUser(response.data))
      .catch((error) => console.error("Error fetching user data:", error));

    // Fetch items for the user
    axios
      .get(`https://auction-0u10.onrender.com/api/userItems/${user_id}`, {
        withCredentials: true,
      })
      .then((response) => {
        const itemsData = response.data.map((itemData) => ({
          id: itemData.Item.item_id,
          name: itemData.Item.name,
          price: itemData.Item.price,
          description: itemData.Item.description,
          image: itemData.Item.img_url || "https://via.placeholder.com/300",
        }));
        setItems(itemsData);
        setFilteredItems(itemsData);
      })
      .catch((error) => console.error("Error fetching items:", error));
  }, [user_id]);

  // Filter items based on selected filters
  const handleFilter = useCallback(
    (priceRange, categoryState, dateRange) => {
      const filtered = items.filter((item) => {
        // Check category status
        const categoryStatus = categoryState[item.category];
        const isCategoryIncluded = categoryStatus === "selected";
        const isCategoryExcluded = categoryStatus === "excluded";

        if (isCategoryExcluded) return false;
        if (
          Object.values(categoryState).some(
            (status) => status === "selected"
          ) &&
          !isCategoryIncluded
        )
          return false;

        // Check price range
        const isWithinPriceRange =
          item.price >= priceRange[0] && item.price <= priceRange[1];

        // Check date range
        const itemDate = new Date(item.startDate);
        const startDate = dateRange.start ? new Date(dateRange.start) : null;
        const endDate = dateRange.end ? new Date(dateRange.end) : null;
        const isWithinDateRange =
          (!startDate || itemDate >= startDate) &&
          (!endDate || itemDate <= endDate);

        return isWithinPriceRange && isWithinDateRange;
      });

      setFilteredItems(filtered);
    },
    [items]
  );

  if (!user) return <p>Loading...</p>;

  return (
    <>
      <Navbar />
      <Container className="mt-4">
        <Row>
          {/* Profile Section */}
          <Col md={3} className="text-center">
            <Image
              src={user.image || "https://via.placeholder.com/150"}
              roundedCircle
              className="mb-3"
              alt="Profile Picture"
              style={{ width: "150px", height: "150px" }}
            />
            <h4>
              {user.name}{" "}
              {user.verified && <Badge bg="success">Verified</Badge>}
            </h4>
            <p className="text-muted">{user.role}</p>
            <SideNav />
          </Col>

          {/* Items and Filters Section */}
          <Col md={9}>
            <Row>
              {/* Filters */}
              <Col md={3}>
                <PriceCategoryAndDateFilterComponent onFilter={handleFilter} />
              </Col>

              {/* Items */}
              <Col md={9}>
                <Row>
                  {filteredItems.length > 0 ? (
                    filteredItems.map((item) => (
                      <Col md={6} key={item.id}>
                        <Link
                          to={`/item/${item.id}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Card className="mb-4">
                            <Card.Img
                              variant="top"
                              src={item.image}
                              alt={item.name}
                            />
                            <Card.Body>
                              <Card.Title>{item.name}</Card.Title>
                              <Card.Text>${item.price}</Card.Text>
                              <Card.Text>{item.description}</Card.Text>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    ))
                  ) : (
                    <p>No items found that match your filters.</p>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserProfilePage;
