import React, { useState } from "react";

const OrganizationDashboard = () => {
  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState(null);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [message, setMessage] = useState(""); // For feedback to the user

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", organizationName);
    formData.append("email", email);
    formData.append("image", image);
    formData.append("people", JSON.stringify(selectedPeople));
    console.log("people", JSON.stringify(selectedPeople));
    try {
      const response = await fetch(
        "https://auction-0u10.onrender.com/api/organizations",
        {
          method: "POST",
          body: formData,
          headers: {
            // You don't need 'Content-Type' here because `fetch` will automatically handle it for `FormData`
          },
          credentials: "include", // To send cookies (for JWT, session, etc.)
        }
      );

      const result = await response.json();

      if (response.ok) {
        setMessage("Organization created successfully!");
        // Clear form after submit
        setOrganizationName("");
        setEmail("");
        setImage(null);
        setSelectedPeople([]);
      } else {
        setMessage(`Error: ${result.message}`);
      }
    } catch (error) {
      setMessage("An error occurred while creating the organization.");
    }
  };

  // Handle image file upload
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  // Add a new person to the organization
  const addPerson = (personEmail) => {
    if (personEmail && !selectedPeople.includes(personEmail)) {
      setSelectedPeople([...selectedPeople, personEmail]);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Register Organization</h2>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleSubmit} className="card p-4 shadow-sm">
        <div className="mb-3">
          <label htmlFor="organizationName" className="form-label">
            Organization Name:
          </label>
          <input
            type="text"
            id="organizationName"
            className="form-control"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="imageUpload" className="form-label">
            Upload Image:
          </label>
          <input
            type="file"
            id="imageUpload"
            className="form-control"
            onChange={handleImageChange}
            accept="image/*"
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="adminEmail" className="form-label">
            Admin Email:
          </label>
          <input
            type="email"
            id="adminEmail"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="addPersonEmail" className="form-label">
            Add People:
          </label>
          <input
            type="email"
            id="addPersonEmail"
            className="form-control"
            placeholder="Enter person's email"
            onBlur={(e) => addPerson(e.target.value)}
          />
          <ul className="list-group mt-2">
            {selectedPeople.map((person, index) => (
              <li key={index} className="list-group-item">
                {person}
              </li>
            ))}
          </ul>
        </div>

        <button type="submit" className="btn btn-primary w-100">
          Submit
        </button>
      </form>
    </div>
  );
};

export default OrganizationDashboard;
