import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa"; // For the verified badge
import Navbar from "./Navbar";
import axios from "axios"; // To handle API requests
import { useParams } from "react-router-dom"; // For accessing route parameters

const OrganizationPage = () => {
  const { orgId } = useParams(); // Get the orgId from the URL params
  const [organizationData, setOrganizationData] = useState(null); // State to hold organization data
  const [activeSection, setActiveSection] = useState("items"); // State to toggle between sections

  useEffect(() => {
    // Fetch the organization data when the component mounts
    const fetchOrganizationData = async () => {
      try {
        const response = await axios.get(
          `https://auction-0u10.onrender.com/api/organizations/${orgId}`,
          { withCredentials: true }
        );
        setOrganizationData(response.data);
        console.log("this is response data", response.data);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };

    fetchOrganizationData();
  }, [orgId]);

  // Return a loading state while the data is being fetched
  if (!organizationData) {
    return <div>Loading...</div>;
  }

  // Extract relevant data from the API response
  const { isOwner, usersWithItems } = organizationData;

  return (
    <>
      <Navbar searchType="organization" />{" "}
      <div className="container">
        {/* Organization Header */}
        <div className="row align-items-center my-4">
          <div className="col-md-4">
            <img
              src="https://via.placeholder.com/300"
              alt="Organization"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-md-8">
            <h1 className="display-4">
              Organization Title <FaCheckCircle className="text-success" />
            </h1>
            <p className="lead">
              This organization is verified
              {isOwner && <span> - You are the owner</span>}
            </p>
          </div>
        </div>

        {/* Section Toggle Buttons */}
        <div className="my-4 d-flex justify-content-center">
          <button
            className={`btn ${
              activeSection === "items" ? "btn-primary" : "btn-outline-primary"
            } mx-2`}
            onClick={() => setActiveSection("items")}
          >
            User Items
          </button>
          <button
            className={`btn ${
              activeSection === "users" ? "btn-primary" : "btn-outline-primary"
            } mx-2`}
            onClick={() => setActiveSection("users")}
          >
            Users
          </button>
        </div>

        {/* Render the section based on active state */}
        <div className="my-5">
          {activeSection === "items" ? (
            <section>
              <h2>User Items</h2>
              {usersWithItems.map(({ user, items }) => (
                <div key={user.user_id}>
                  <h3>
                    {user.name} ({user.email})
                  </h3>
                  {items.length > 0 ? (
                    <ul className="list-group">
                      {items.map((item) => (
                        <li key={item.item_id} className="list-group-item">
                          {item.name} - ${item.price}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No items available.</p>
                  )}
                </div>
              ))}
            </section>
          ) : (
            <section>
              <h2>Members</h2>
              {/* Users in Table Format */}
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {usersWithItems.map(({ user }) => (
                    <tr key={user.user_id}>
                      <td>{user.user_id}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default OrganizationPage;
