import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext"; // Import AuthContext
import Navbar from "./Navbar";

export default function Signin() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const { handleSignIn } = useContext(AuthContext); // Access handleSignIn from AuthContext

  function handleChange(event) {
    const { name, value } = event.target;
    setUser((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    handleApi();
  }

  function handleGoogle() {
    window.location.href = "https://auction-0u10.onrender.com/auth/google";
  }
  function handleFacebook() {
    window.location.href = "https://auction-0u10.onrender.com/auth/facebook";
  }

  function handleApi() {
    fetch("https://auction-0u10.onrender.com/api/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
      credentials: "include",
    })
      .then(async (response) => {
        const data = await response.json();
        console.log("DATAAAAA", data);
        if (response.ok) {
          handleSignIn(data.user.user_id); // Set authenticated state globally
          navigate("/"); // Redirect to Main or home page
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">Sign In</h3>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      id="email"
                      placeholder="Enter your email"
                      name="email"
                      value={user.email}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={handleChange}
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                      value={user.password}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Sign In
                  </button>
                </form>

                <div className="text-center mt-3">
                  <p>Or sign in with:</p>
                  <button
                    className="btn btn-danger w-100 mb-2"
                    onClick={handleGoogle}
                  >
                    Sign in with Google
                  </button>
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleFacebook}
                  >
                    Sign in with Facebook
                  </button>
                </div>

                <p className="text-center mt-3">
                  Don't have an account? <a href="/">Register here</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
