import React, { useState, useCallback, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom"; // Import useParamsimport Navbar from "./Navbar";
import PriceCategoryAndDateFilterComponent from "./Slider";
import Navbar from "./Navbar";

export default function SearchPage() {
  const { searchType } = useParams(); // Retrieve searchType from URL
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const location = useLocation();

  useEffect(() => {
    // Fetch items from backend
    console.log("SENDIN");
    const fetchItems = async (query = "") => {
      try {
        console.log("SENTTHE REQ");
        const response = await fetch(
          `https://auction-0u10.onrender.com/api/${searchType}/search?q=${encodeURIComponent(
            query
          )}`,
          {
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("DATA", data);
          setCards(data);
          setFilteredCards(data);
        } else {
          console.error("Failed to fetch items:", response.status);
        }
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };
    const params = new URLSearchParams(location.search);
    const query = params.get("q") || "";
    fetchItems(query);
  }, [location.search, searchType]);

  const handleFilter = useCallback(
    (priceRange, selectedCategories, dateRange) => {
      const isFilterActive =
        priceRange[0] > 0 ||
        priceRange[1] < 500 ||
        Object.values(selectedCategories).some((status) => status !== null) ||
        dateRange.start ||
        dateRange.end;

      if (!isFilterActive) {
        setFilteredCards(cards);
        return;
      }

      const filtered = cards.filter((card) => {
        const categoryStatus = selectedCategories[card.category_id] || null;
        const cardDate = new Date(card.start_date);
        const startDate = dateRange.start ? new Date(dateRange.start) : null;
        const endDate = dateRange.end ? new Date(dateRange.end) : null;

        const priceCondition =
          card.price >= priceRange[0] && card.price <= priceRange[1];
        const categoryCondition =
          categoryStatus === null || categoryStatus === "selected";
        const dateCondition =
          (!startDate || cardDate >= startDate) &&
          (!endDate || cardDate <= endDate);

        return (
          priceCondition &&
          categoryCondition &&
          !(categoryStatus === "excluded") &&
          dateCondition
        );
      });

      setFilteredCards(filtered);
    },
    [cards]
  );

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-3">
            <PriceCategoryAndDateFilterComponent onFilter={handleFilter} />
          </div>
          <div className="col-md-9">
            <div className="row">
              {filteredCards.length > 0 ? (
                filteredCards.map((card) => (
                  <div className="col-md-4 mb-4" key={card.item_id}>
                    <div className="card">
                      <img
                        src={card.img_url}
                        className="card-img-top"
                        alt={card.name}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{card.name}</h5>
                        <p className="card-text">{card.description}</p>
                        <p className="card-text">Price: ${card.price}</p>
                        <Link
                          to={`/item/${card.item_id}`}
                          className="btn btn-primary"
                        >
                          View Item
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No items found that match your filters.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
