import { useTranslation } from "react-i18next";

export default function Payment() {
  const { t } = useTranslation();

  return (
    <>
      <body className="bg-light">
        <div className="container">
          <main>
            <div className="py-5 text-center">
              <img
                className="d-block mx-auto mb-4"
                src=""
                alt=""
                width="72"
                height="57"
              />
              <h2>{t("payment.title")}</h2>
              <p className="lead">{t("payment.leadText")}</p>
            </div>

            <div className="row g-5">
              <div className="col-md-7 col-lg-8">
                <h4 className="mb-3">{t("payment.billingAddress")}</h4>
                <form className="needs-validation" noValidate>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <label htmlFor="firstName" className="form-label">
                        {t("payment.firstNameLabel")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder=""
                        value=""
                        required
                      />
                      <div className="invalid-feedback">
                        {t("payment.firstNameLabel")} is required.
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label htmlFor="lastName" className="form-label">
                        {t("payment.lastNameLabel")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder=""
                        value=""
                        required
                      />
                      <div className="invalid-feedback">
                        {t("payment.lastNameLabel")} is required.
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="username" className="form-label">
                        {t("payment.usernameLabel")}
                      </label>
                      <div className="input-group has-validation">
                        <span className="input-group-text">@</span>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          placeholder={t("payment.usernamePlaceholder")}
                          required
                        />
                        <div className="invalid-feedback">
                          {t("payment.usernameLabel")} is required.
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="email" className="form-label">
                        {t("payment.emailLabel")}{" "}
                        <span className="text-muted">
                          ({t("payment.emailOptional")})
                        </span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder={t("payment.emailPlaceholder")}
                      />
                      <div className="invalid-feedback">
                        Please enter a valid {t("payment.emailLabel")} for
                        shipping updates.
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">
                        {t("payment.addressLabel")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder={t("payment.addressPlaceholder")}
                        required
                      />
                      <div className="invalid-feedback">
                        {t("payment.addressLabel")} is required.
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="address2" className="form-label">
                        {t("payment.address2Label")}{" "}
                        <span className="text-muted">
                          ({t("payment.emailOptional")})
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address2"
                        placeholder={t("payment.address2Placeholder")}
                      />
                    </div>

                    <div className="col-md-5">
                      <label htmlFor="country" className="form-label">
                        {t("payment.countryLabel")}
                      </label>
                      <select className="form-select" id="country" required>
                        <option value="">
                          {t("payment.countryPlaceholder")}
                        </option>
                        <option>{t("payment.countryOptionUS")}</option>
                      </select>
                      <div className="invalid-feedback">
                        {t("payment.countryLabel")} is required.
                      </div>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="state" className="form-label">
                        {t("payment.stateLabel")}
                      </label>
                      <select className="form-select" id="state" required>
                        <option value="">
                          {t("payment.statePlaceholder")}
                        </option>
                        <option>{t("payment.stateOptionCA")}</option>
                      </select>
                      <div className="invalid-feedback">
                        {t("payment.stateLabel")} is required.
                      </div>
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="zip" className="form-label">
                        {t("payment.zipLabel")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="zip"
                        placeholder=""
                        required
                      />
                      <div className="invalid-feedback">
                        {t("payment.zipLabel")} is required.
                      </div>
                    </div>
                  </div>

                  <hr className="my-4" />

                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="same-address"
                    />
                    <label className="form-check-label" htmlFor="same-address">
                      {t("payment.shippingSameAsBilling")}
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="save-info"
                    />
                    <label className="form-check-label" htmlFor="save-info">
                      {t("payment.saveInfo")}
                    </label>
                  </div>

                  <hr className="my-4" />

                  <h4 className="mb-3">{t("payment.paymentTitle")}</h4>

                  <div className="my-3">
                    <div className="form-check">
                      <input
                        id="credit"
                        name="paymentMethod"
                        type="radio"
                        className="form-check-input"
                        checked
                        required
                      />
                      <label className="form-check-label" htmlFor="credit">
                        {t("payment.creditCardLabel")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        id="debit"
                        name="paymentMethod"
                        type="radio"
                        className="form-check-input"
                        required
                      />
                      <label className="form-check-label" htmlFor="debit">
                        {t("payment.debitCardLabel")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        id="paypal"
                        name="paymentMethod"
                        type="radio"
                        className="form-check-input"
                        required
                      />
                      <label className="form-check-label" htmlFor="paypal">
                        {t("payment.paypalLabel")}
                      </label>
                    </div>
                  </div>

                  <div className="row gy-3">
                    <div className="col-md-6">
                      <label htmlFor="cc-name" className="form-label">
                        {t("payment.nameOnCardLabel")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cc-name"
                        placeholder={t("payment.nameOnCardPlaceholder")}
                        required
                      />
                      <small className="text-muted">
                        {t("payment.nameOnCardPlaceholder")}
                      </small>
                      <div className="invalid-feedback">
                        {t("payment.nameOnCardLabel")} is required.
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="cc-number" className="form-label">
                        {t("payment.creditCardNumberLabel")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cc-number"
                        placeholder=""
                        required
                      />
                      <div className="invalid-feedback">
                        {t("payment.creditCardNumberLabel")} is required.
                      </div>
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="cc-expiration" className="form-label">
                        {t("payment.expirationLabel")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cc-expiration"
                        placeholder=""
                        required
                      />
                      <div className="invalid-feedback">
                        {t("payment.expirationLabel")} is required.
                      </div>
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="cc-cvv" className="form-label">
                        {t("payment.cvvLabel")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cc-cvv"
                        placeholder=""
                        required
                      />
                      <div className="invalid-feedback">
                        {t("payment.cvvLabel")} is required.
                      </div>
                    </div>
                  </div>

                  <hr className="my-4" />

                  <button
                    className="w-100 btn btn-primary btn-lg"
                    type="submit"
                  >
                    {t("payment.continueToCheckoutButton")}
                  </button>
                </form>
              </div>
            </div>
          </main>

          <footer className="my-5 pt-5 text-muted text-center text-small">
            <p className="mb-1">{t("payment.footerText")}</p>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="/">{t("payment.footerPrivacy")}</a>
              </li>
              <li className="list-inline-item">
                <a href="/">{t("payment.footerTerms")}</a>
              </li>
              <li className="list-inline-item">
                <a href="/">{t("payment.footerSupport")}</a>
              </li>
            </ul>
          </footer>
        </div>
      </body>
    </>
  );
}
